import https from "./https";
import store from "@/store";

const organization = store.state.liffGeneral.orgCode;

const form = {
  getForm(id, params = {}) {
    return https.get(`${organization}/liff/forms/${id}`, { params });
  },
  saveResult(id, data) {
    return https.post(`${organization}/liff/forms/${id}/save-result`, data);
  },
  getFormResult(id, params = {}) {
    return https.get(`${organization}/liff/forms/${id}/check-result`, { params });
  },
  setCheckinSuccess(id) {
    return https.post(`${organization}/liff/forms/${id}/set-checkin-success`);
  },
  setCheckinExpired(id) {
    return https.post(`${organization}/liff/forms/${id}/set-checkin-expired`);
  },
};

export default form;
