export function parseFormSubjects(form) {
  if (form.subjects == undefined || form.subjects === null) {
    return [];
  }

  return form.subjects.sort((a, b) => a.order - b.order).map((subject) => {
    let config = subject.config;

    return {
      ...config,
      id: subject.id,
      type: subject.type,
    }
  });
}
